<template>
  <b-card>

    <b-row>
      <!-- image -->
      <b-col
        cols="12"
        md="6"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewEl"
                rounded
                :src="localData.image"
                height="80"
              />
            </b-link>
            <!--/ image -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- button -->
            <b-button
              ref="refSelectImage"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputEl.$el.click()"
            >
              {{ $t('Select Image') }}
            </b-button>
            <b-button
              v-if="imageFile !== null"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="saveChangeImage"
            >
              {{ $t('Save Change Image') }}
            </b-button>
            <b-form-file
              ref="refInputEl"
              v-model="imageFile"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputImageRenderer"
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
              @click="resetImage"
            >
              {{ $t('Reset Image') }}
            </b-button>
            <!--/ reset -->
            <b-card-text>{{ $t('Image') }}</b-card-text>
          </b-media-body>
        </b-media>
      </b-col>

      <!-- image_en -->
      <b-col
        cols="12"
        md="6"
      >
        <b-media no-body>
          <b-media-aside>
            <b-link>
              <b-img
                ref="previewElEn"
                rounded
                :src="localData.image_en"
                height="80"
              />
            </b-link>
            <!--/ image -->
          </b-media-aside>

          <b-media-body class="mt-75 ml-75">
            <!-- button -->
            <b-button
              ref="refSelectImageEn"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="$refs.refInputElEn.$el.click()"
            >
              {{ $t('Select Image') }}
            </b-button>
            <b-button
              v-if="imageFileEn !== null"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              size="sm"
              class="mb-75 mr-75"
              @click="saveChangeImageEn"
            >
              {{ $t('Save Change Image') }}
            </b-button>
            <b-form-file
              ref="refInputElEn"
              v-model="imageFileEn"
              accept=".jpg, .png, .gif"
              :hidden="true"
              plain
              @input="inputImageRendererEn"
            />
            <!--/ upload button -->

            <!-- reset -->
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              size="sm"
              class="mb-75 mr-75"
              @click="resetImageEn"
            >
              {{ $t('Reset Image') }}
            </b-button>
            <!--/ reset -->
            <b-card-text>{{ `${$t('Image')} (${$t('English')})` }}</b-card-text>
          </b-media-body>
        </b-media>
      </b-col>
    </b-row>

    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- form -->
      <b-form
        ref="refForm"
        class="mt-1"
        @submit.prevent="handleSubmit(onSubmit)"
        @reset.prevent="resetLocalForm"
      >
        <b-row>
          <!-- brand -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('Brand')"
              label-for="coupon-brand"
            >
              <v-select
                v-model="localData.brand_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="localData.brand_options"
                :reduce="val => val.value"
                :clearable="false"
                input-id="coupon-brand"
              />
            </b-form-group>
          </b-col>

          <!-- merchant -->
          <b-col
            cols="12"
            md="6"
          >
            <b-form-group
              :label="$t('Merchant')"
              label-for="coupon-merchant"
            >
              <v-select
                v-model="localData.merchant_id"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="localData.merchant_options"
                :reduce="val => val.value"
                :clearable="false"
                input-id="coupon-merchant"
              />
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- code -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              :label="$t('Code')"
              label-for="coupon-code"
            >

              <validation-provider
                #default="validationContext"
                :name="$t('Code')"
                rules="required"
              >
                <b-form-input
                  id="coupon-code"
                  v-model="localData.code"
                  :placeholder="$t('Code')"
                  :state="getValidationState(validationContext)"
                  :disabled="busy"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>

            </b-form-group>
          </b-col>

          <!-- value -->
          <b-col
            cols="12"
            sm="6"
          >
            <b-form-group
              :label="$t('Value')"
              label-for="coupon-value"
            >

              <validation-provider
                #default="validationContext"
                :name="$t('Value')"
                rules="required"
              >
                <b-form-input
                  id="coupon-value"
                  v-model="localData.value"
                  :placeholder="$t('Value')"
                  :state="getValidationState(validationContext)"
                  :disabled="busy"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>

            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- start date -->
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group
              label-for="coupon-start-date"
              :label="$t('Start Date')"
            >
              <flat-pickr
                id="coupon-start-date"
                v-model="localData.start_date"
                :config="{ dateFormat: 'd F Y' }"
                class="form-control"
                :placeholder="$t('Start Date')"
              />
            </b-form-group>
          </b-col>

          <!-- stop date -->
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group
              label-for="coupon-stop-date"
              :label="$t('Stop Date')"
            >
              <flat-pickr
                id="coupon-stop-date"
                v-model="localData.stop_date"
                :config="{ dateFormat: 'd F Y' }"
                class="form-control"
                :placeholder="$t('Stop Date')"
              />
            </b-form-group>
          </b-col>

          <!-- status -->
          <b-col
            cols="12"
            sm="4"
          >
            <b-form-group
              v-if="localData.status"
              :label="$t('Status')"
              label-for="coupon-status"
            >
              <b-badge
                pill
                :variant="(skin === 'dark')?
                  `light-${resolveCouponStatusVariant(localData.status)}` :
                  resolveCouponStatusVariant(localData.status)"
                class="text-capitalize p-1"
                style="font-size: 0.9em;"
              >
                {{ $t(textFirstUpper(localData.status)) }}
              </b-badge>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <!-- create-date -->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              :label="$t('Create Date/Time')"
              label-for="coupon-create-date"
            >
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-success` : `success`"
                class="text-capitalize p-1"
                style="font-size: 0.9em;"
              >
                {{ localData.create_datetime }}
              </b-badge>
            </b-form-group>
          </b-col>

          <!-- create-by -->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              :label="$t('Create By')"
              label-for="coupon-create-by"
            >
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-success` : `success`"
                class="text-capitalize p-1"
                style="font-size: 0.9em;"
              >
                {{ localData.create_by }}
              </b-badge>
            </b-form-group>
          </b-col>

          <!-- update-date -->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              :label="$t('Update Date/Time')"
              label-for="coupon-update-date"
            >
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-warning` : `warning`"
                class="text-capitalize p-1"
                style="font-size: 0.9em;"
              >
                {{ localData.update_datetime }}
              </b-badge>
            </b-form-group>
          </b-col>

          <!-- update-by -->
          <b-col
            cols="12"
            sm="3"
          >
            <b-form-group
              :label="$t('Update By')"
              label-for="coupon-update-by"
            >
              <b-badge
                pill
                :variant="(skin === 'dark')? `light-warning` : `warning`"
                class="text-capitalize p-1"
                style="font-size: 0.9em;"
              >
                {{ localData.update_by }}
              </b-badge>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col cols="8">
            <b-button
              ref="submit"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mt-2 mr-1"
              :disabled="busy"
              @click="onSubmit"
            >
              {{ $t('Save changes') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-secondary"
              type="reset"
              class="mt-2"
              @click.prevent="resetLocalForm"
            >
              {{ $t('Reset') }}
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="text-right d-none d-md-block d-lg-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoCouponList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('Coupon List')}` }}</span>
            </b-button>
          </b-col>
          <b-col
            cols="12"
            md="4"
            class="d-lg-none d-md-none d-sm-block"
          >
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              variant="outline-primary"
              type="button"
              class="mt-2"
              @click.prevent="gotoCouponList"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                size="12"
              />
              <span class="ml-25 align-middle"> {{ `${$t('Goto')} ${$t('Coupon List')}` }}</span>
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>

    <b-overlay
      :show="busy"
      no-wrap
      @shown="onShown"
      @hidden="onHidden"
    >
      <template #overlay>
        <div
          v-if="processing"
          class="text-center p-2 bg-primary text-light rounded"
        >
          <feather-icon
            icon="UploadCloudIcon"
            size="20"
          />
          <div class="mb-2">
            {{ `${$t('Processing')}...` }}
          </div>
          <b-progress
            min="1"
            max="10"
            :value="counter"
            variant="success"
            height="6px"
            class="mx-n1"
          />
        </div>
        <div
          v-else
          ref="dialog"
          tabindex="-1"
          role="dialog"
          aria-modal="false"
          aria-labelledby="form-confirm-label"
          class="text-center p-3"
        >
          <b-card-text
            class="font-weight-bolder"
          >
            {{ $t('Are you sure?') }}
          </b-card-text>
          <div class="d-flex">
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mr-3"
              @click="onCancel"
            >
              {{ $t('Cancel') }}
            </b-button>
            <b-button
              ref="dialogOk"
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="outline-success"
              @click="onOK"
            >
              {{ $t('Ok') }}
            </b-button>
          </div>
        </div>
      </template>
    </b-overlay>
  </b-card>
</template>

<script>
import {
  BFormFile, BButton, BForm, BFormGroup, BFormInput, BRow, BCol, BCard, BCardText, BMedia, BMediaAside, BMediaBody, BLink, BImg,
  BOverlay,
  BProgress,
  BFormInvalidFeedback,
  BBadge,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useInputImageRenderer, useInputImageRendererEn } from '@core/comp-functions/forms/form-utils'
import router from '@/router'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import formValidation from '@core/comp-functions/forms/form-validation'
import vSelect from 'vue-select'
import {
  ValidationProvider, ValidationObserver, localize,
} from 'vee-validate'
import { required } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import couponStoreModule from '../couponStoreModule'
import useCouponList from '../coupon-list/useCouponList'

export default {
  components: {
    BButton,
    BForm,
    BImg,
    BFormFile,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BOverlay,
    BProgress,
    BFormInvalidFeedback,
    BBadge,
    vSelect,
    flatPickr,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      localData: {},
      userData: JSON.parse(localStorage.getItem('userData')),
      skin: store.state.appConfig.layout.skin,
      imageFile: null,
      imageFileEn: null,
      required,
      busy: false,
      processing: false,
      counter: 1,
      interval: null,
      isAction: null,
    }
  },
  mounted() {
    localize(this.$i18n.locale)
    this.getCoupon()
  },
  methods: {
    getCoupon() {
      store
        .dispatch('coupon-store/getCoupon', { id: router.currentRoute.params.id })
        .then(response => {
          const okMsg = this.$i18n.t('Process on successfully')
          this.$toast({
            component: ToastificationContent,
            props: {
              title: okMsg,
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          },
          {
            position: 'bottom-right',
          })
          this.localData = response.data
        })
        .catch(error => {
          const { response, message } = error
          if (response) {
            const errorMsg = this.$i18n.t(response?.data.error)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: errorMsg,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          } else if (message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: error?.message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            },
            {
              position: 'bottom-right',
            })
          }
        })
    },
    onShown() {
      // Focus the dialog prompt
      this.$refs.dialogOk.focus()
    },
    onHidden() {
      // In this case, we return focus to the submit button
      // You may need to alter this based on your application requirements
      this.$refs.submit.focus()
    },
    onSubmit() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          this.processing = false
          this.busy = true
          this.isAction = 'form'
        }
      })
    },
    onCancel() {
      this.busy = false
    },
    onOK() {
      this.processing = true
      if (this.isAction === 'form') {
        store.dispatch('coupon-store/editCoupon', { id: this.$router.currentRoute.params.id, data: this.localData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.isAction === 'file') {
        const formData = new FormData()
        formData.append('file', this.imageFile)

        store.dispatch('coupon-store/uploadImageCoupon', { id: this.$router.currentRoute.params.id, data: formData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.imageFile = null
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      } else if (this.isAction === 'fileEn') {
        const formData = new FormData()
        formData.append('file', this.imageFileEn)

        store.dispatch('coupon-store/uploadImageEnCoupon', { id: this.$router.currentRoute.params.id, data: formData })
          .then(() => {
            const okMsg = this.$i18n.t('Process on successfully')
            this.$toast({
              component: ToastificationContent,
              props: {
                title: okMsg,
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            },
            {
              position: 'bottom-right',
            })
            this.busy = false
            this.processing = false
            this.imageFileEn = null
          })
          .catch(error => {
            const { response, message } = error
            if (response) {
              let errorMsg = this.$i18n.t(response?.data.error)
              if (errorMsg === '') {
                errorMsg = this.$i18n.t(response?.data.message)
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: errorMsg,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            } else if (message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: error?.message,
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              },
              {
                position: 'bottom-right',
              })
            }
            this.busy = false
            this.processing = false
          })
      }
      this.$refs.submit.focus()
    },
    resetLocalForm() {
      this.getCoupon()
      this.resetImage()
      this.resetImageEn()
      this.$refs.refFormObserver.reset()
      this.$refs.submit.focus()
    },
    resetImage() {
      this.$refs.previewEl.src = this.localData.image
      this.imageFile = null
    },
    saveChangeImage() {
      if (this.imageFile !== null) {
        this.processing = false
        this.busy = true
        this.isAction = 'file'
      } else {
        const msg = this.$i18n.t('Please select image file')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
        this.busy = false
        this.processing = false
      }
    },
    resetImageEn() {
      this.$refs.previewElEn.src = this.localData.image_en
      this.imageFileEn = null
    },
    saveChangeImageEn() {
      if (this.imageFileEn !== null) {
        this.processing = false
        this.busy = true
        this.isAction = 'fileEn'
      } else {
        const msg = this.$i18n.t('Please select image file')
        this.$toast({
          component: ToastificationContent,
          props: {
            title: msg,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        },
        {
          position: 'bottom-right',
        })
        this.busy = false
        this.processing = false
      }
    },
    gotoCouponList() {
      this.$router.push({ name: 'ecoupon-coupon-list' })
    },
  },
  setup() {
    const refInputEl = ref(null)
    const previewEl = ref(null)

    const refInputElEn = ref(null)
    const previewElEn = ref(null)

    const { inputImageRenderer } = useInputImageRenderer(refInputEl, base64 => { previewEl.value.src = base64 })
    const { inputImageRendererEn } = useInputImageRendererEn(refInputElEn, base64 => { previewElEn.value.src = base64 })

    const STORE_MODULE_NAME = 'coupon-store'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, couponStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const {
      refFormObserver,
      getValidationState,
      resetForm,
      clearForm,
    } = formValidation()

    const {
      resolveCouponStatusVariant,
      textFirstUpper,
    } = useCouponList()

    return {
      refInputEl,
      previewEl,
      refInputElEn,
      previewElEn,
      inputImageRenderer,
      inputImageRendererEn,

      // Form Validation
      resetForm,
      clearForm,
      refFormObserver,
      getValidationState,

      resolveCouponStatusVariant,
      textFirstUpper,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss">
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.dark-layout {
  div{
      .b-overlay {
        .bg-light {
          background-color: $theme-dark-body-bg !important;
        }
      }
  }
}
</style>
